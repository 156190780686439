import React from 'react';
import {Helmet} from "react-helmet";
import Layout from "../components/layout";
import { graphql } from 'gatsby'

import styles from "./valentinesDay.module.scss";

export default ({ data }) => {
  return (
    <Layout>

      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>Valentine's Day | {data.site.siteMetadata.title}</title>
        <meta name="robots" content="index, follow" />
        <meta name="description" content="We are taking reservations for Valentines Day! $100 per person Prix Fixe Menu. Wednesday February 14th. First Seating reservations starting between: 5:00pm-5:30pm (2hrs per reservation). Second Seating reservations starting between: 7:15pm-7:45pmGet in valentinesDay with us at Fume Restobar!" />
        <meta property="og:title" content={`Valentine's Day | ${data.site.siteMetadata.title}`} />
        <meta property="og:description" content="We are taking reservations for Valentines Day! $100 per person Prix Fixe Menu. Wednesday February 14th. First Seating reservations starting between: 5:00pm-5:30pm (2hrs per reservation). Second Seating reservations starting between: 7:15pm-7:45pmGet in valentinesDay with us at Fume Restobar!" />
        <meta property="og:url" content={data.site.siteMetadata.siteUrl} />
        <meta property="og:site_name" content={data.site.siteMetadata.title} />
        <meta property="og:image" content={`${data.site.siteMetadata.siteUrl}/icons/icon-512x512.png`} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={`Contact | ${data.site.siteMetadata.title}`} />
        <meta name="twitter:image" content={`${data.site.siteMetadata.siteUrl}/icons/icon-512x512.png`} />
      </Helmet>

        {data.allWordpressPage.edges.map(({ node, index }) => (
          <div className={styles.valentinesDay} key={node.slug}>
            <h1>{node.title}</h1>

            <div className={styles.details} dangerouslySetInnerHTML={{__html: node.content }} />
              
          </div>
        ))}

    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title,
        desc,
        siteUrl
      }
    },
    allWordpressPage(
      filter: { slug:{ eq: "valentines-day" }}
    ) {
      edges {
        node {
          slug,
          title,
          content
        }
      }
    }
  }
`
